<template>
  <base-dialog
    :title="$t('base.setCustomField')"
    @cancel="cancel"
    @confirm="confirm"
  >
    <table-list
      :isDefine="true"
      :defaultContent="customFieldJson"
      :columns="columns"
      :showSort="false"
    >
      <!-- 名称 -->
      <template v-slot:label="{ row }">
        <el-input v-model="row.label" />
      </template>
      <!-- 值 -->
      <template v-slot:value="{ row }">
        <el-input v-model="row.value" />
      </template>
      <!-- 值 -->
      <template v-slot:option="{ index }">
        <el-button
          type="text"
          v-if="index || customFieldJson.length >= 2"
          @click="deleteField(index)"
        >
          {{ $t('base.del') }}
        </el-button>
        <el-button
          type="text"
          v-if="index === customFieldJson.length - 1"
          @click="addField"
        >
          {{ $t('base.add') }}
        </el-button>
      </template>
    </table-list>
  </base-dialog>
</template>

<script>
export default {
  props: {
    // 当前已设置的自定义字段
    customFieldJson: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      columns: [
        // lang:名称
        {
          label: this.$t('base.name'),
          prop: 'label',
        },
        // lang:值
        {
          label: this.$t('base.value'),
          prop: 'value',
        },
        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
          width: '100px',
        },
      ],
    }
  },
  methods: {
    // 取消
    cancel() {
      this.$emit('cancel')
    },
    // 确认
    confirm() {
      if (this.customFieldJson.some((item) => !item.label || !item.value)) {
        this.$element.showMsg(this.$t('base.writeTips'), 'warning')
        return
      }
      this.$emit('confirm')
    },
    // 删除
    deleteField(index) {
      this.customFieldJson.splice(index, 1)
    },
    // 添加
    addField() {
      this.customFieldJson.push({
        label: '',
        value: '',
      })
    },
  },
}
</script>